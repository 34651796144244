:root {
  color-scheme: light only;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./fonts/NeueMontreal-Regular.woff2") format("woff2"),
    url("./fonts/NeueMontreal-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./fonts/NeueMontreal-Medium.woff2") format("woff2"),
    url("./fonts/NeueMontreal-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./fonts/NeueMontreal-Bold.woff2") format("woff2"),
    url("./fonts/NeueMontreal-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

* {
  padding: 0px;
  margin: 0px;
  outline: none;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "NeueMontreal";
  font-weight: 400;
  letter-spacing: -0.5px;
}

html,
body {
  background: #f8f6ef;
}
